import React from "react";
import Layout from "../components/Layout";

export default class Impress extends React.Component {
  render() {
    const { location } = this.props;
    return (
      <Layout location={location}>
        <section className="section">
          <div className="columns is-multiline is-vcentered has-padding-bottom has-side-padding">
            <div
              className={"column is-10 is-offset-1"}
              style={{ marginTop: 100 }}
            >
              <div className="columns is-multiline is-vcentered has-border-bottom has-padding-bottom is-between is-flex">
                <h3
                  className={"title is-dark-snd is-inline-block is-marginless"}
                >{`Angaben gemäß § 5 TMG:`}</h3>
              </div>
              <div className="columns ">
                <div
                  className="column is-12"
                  style={{ paddingTop: 16, paddingLeft: 0 }}
                >
                  <p className="is-text is-raleway">Markus Kraus</p>
                  <p className="is-text is-raleway">Wohnmobile Kraus</p>
                  <p className="is-text is-raleway">Tangastrasse 47</p>
                  <p className="is-text is-raleway">81827 München</p>
                </div>
              </div>
            </div>

            <div
              className={"column is-10 is-offset-1"}
              style={{ marginTop: 32 }}
            >
              <div className="columns is-multiline is-vcentered has-padding-bottom is-between is-flex">
                <h3
                  className={"title is-dark-snd is-inline-block is-marginless"}
                >{`Kontakt:`}</h3>
              </div>
              <div className="columns ">
                <div
                  className="column is-12"
                  style={{ paddingTop: 16, paddingLeft: 0 }}
                >
                  <p className="is-text is-raleway">
                    Telefon: 0177 - 719 77 28
                  </p>
                  <p className="is-text is-raleway">
                    E-Mail: info@wohnmobilekraus.de
                  </p>
                </div>
              </div>
            </div>

            <div
              className={"column is-10 is-offset-1 has-border-bottom"}
              style={{ marginTop: 32 }}
            >
              <div className="columns is-multiline is-vcentered is-between is-flex ">
                <h3
                  className={"title is-dark-snd is-inline-block is-marginless"}
                >{`Umsatzsteuer-ID:`}</h3>
              </div>
              <div className="columns ">
                <div
                  className="column is-12"
                  style={{ paddingTop: 16, paddingLeft: 0 }}
                >
                  <p className="is-text is-raleway ">
                    Umsatzsteuer-Identifikationsnummer gemäß §27 a
                    Umsatzsteuergesetz: DEXXX
                  </p>
                </div>
              </div>
            </div>

            <div
              className={"column is-10 is-offset-1 has-border-bottom"}
              style={{ marginTop: 32 }}
            >
              <div className="columns is-multiline is-vcentered is-between is-flex ">
                <h3
                  className={"title is-dark-snd is-inline-block is-marginless"}
                >{`Fahrzeug Fotografie:`}</h3>
              </div>
              <div className="columns ">
                <div
                  className="column is-12"
                  style={{ paddingTop: 16, paddingLeft: 0 }}
                >
                  <p className="is-text is-raleway ">© Gina Bolle - München</p>
                </div>
              </div>
            </div>

            <div
              className={"column is-10 is-offset-1 has-border-bottom"}
              style={{ marginTop: 32 }}
            >
              <div className="columns is-multiline is-vcentered is-between is-flex ">
                <h3
                  className={"title is-dark-snd is-inline-block is-marginless"}
                >{`Programmierung:`}</h3>
              </div>
              <div className="columns ">
                <div
                  className="column is-12"
                  style={{ paddingTop: 16, paddingLeft: 0 }}
                >
                  <p className="is-text is-raleway ">Falk Michél</p>
                  <a
                    className={"is-link"}
                    target={"_blank"}
                    href={"https://falkmichel.com"}
                  >
                    falkmichel.com
                  </a>
                </div>
              </div>
            </div>

            <div
              className={"column is-10 is-offset-1"}
              style={{ marginTop: 32 }}
            >
              <div className="columns is-multiline is-vcentered is-between is-flex ">
                <h3
                  className={"title is-dark-snd is-inline-block is-marginless"}
                >{`Haftungsausschluss (Disclaimer):`}</h3>
              </div>
              <div className="columns">
                <div
                  className="column is-12"
                  style={{ paddingTop: 16, paddingLeft: 0 }}
                >
                  <p className="is-text subtitle is-raleway">
                    Haftung für Inhalte
                  </p>
                  <p className="is-text is-raleway ">
                    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
                    Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
                    verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
                    Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
                    gespeicherte fremde Informationen zu überwachen oder nach
                    Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
                    hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
                    Nutzung von Informationen nach den allgemeinen Gesetzen
                    bleiben hiervon unberührt. Eine diesbezügliche Haftung ist
                    jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
                    Rechtsverletzung möglich. Bei Bekanntwerden von
                    entsprechenden Rechtsverletzungen werden wir diese Inhalte
                    umgehend entfernen.
                  </p>
                </div>
              </div>
              <div className="columns">
                <div
                  className="column is-12"
                  style={{ paddingTop: 16, paddingLeft: 0 }}
                >
                  <p className="is-text subtitle is-raleway">
                    Haftung für Links
                  </p>
                  <p className="is-text is-raleway ">
                    Unser Angebot enthält Links zu externen Webseiten Dritter,
                    auf deren Inhalte wir keinen Einfluss haben. Deshalb können
                    wir für diese fremden Inhalte auch keine Gewähr übernehmen.
                    Für die Inhalte der verlinkten Seiten ist stets der
                    jeweilige Anbieter oder Betreiber der Seiten verantwortlich.
                    Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung
                    auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte
                    waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine
                    permanente inhaltliche Kontrolle der verlinkten Seiten ist
                    jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung
                    nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen
                    werden wir derartige Links umgehend entfernen.
                  </p>
                </div>
              </div>

              <div className="columns">
                <div
                  className="column is-12"
                  style={{ paddingTop: 16, paddingLeft: 0 }}
                >
                  <p className="is-text subtitle is-raleway">Urheberrecht</p>
                  <p className="is-text is-raleway ">
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke
                    auf diesen Seiten unterliegen dem deutschen Urheberrecht.
                    Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art
                    der Verwertung außerhalb der Grenzen des Urheberrechtes
                    bedürfen der schriftlichen Zustimmung des jeweiligen Autors
                    bzw. Erstellers. Downloads und Kopien dieser Seite sind nur
                    für den privaten, nicht kommerziellen Gebrauch gestattet.
                    Soweit die Inhalte auf dieser Seite nicht vom Betreiber
                    erstellt wurden, werden die Urheberrechte Dritter beachtet.
                    Insbesondere werden Inhalte Dritter als solche
                    gekennzeichnet. Sollten Sie trotzdem auf eine
                    Urheberrechtsverletzung aufmerksam werden, bitten wir um
                    einen entsprechenden Hinweis. Bei Bekanntwerden von
                    Rechtsverletzungen werden wir derartige Inhalte umgehend
                    entfernen.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
